var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('card-title',{attrs:{"title":"Listagem de comissões"}})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.hasAccessConcludeCommission)?_c('v-btn',{staticClass:"rounded-md",attrs:{"color":"success","loading":_vm.loading,"disabled":_vm.selectedItems.length === 0},on:{"click":_vm.setConcludedCommission}},[_vm._v(" Concluir Comissões ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.hasAccessCreateCommission)?_c('v-btn',{staticClass:"rounded-md",attrs:{"color":"primary"},on:{"click":_vm.createCommission}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" Cadastrar Comissões ")],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.loadingExport,"color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-file-table-outline")]),_vm._v(" Exportar excel ")],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('vue-to-excel',{attrs:{"fetch":_vm.exportCompleteReport,"fields":_vm.completeReportFiels,"name":"relatorio_comissões_completo.xls","worksheet":"Lançamentos"}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{staticClass:"d-flex align-center justify-space-between"},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-tray-arrow-down")]),_c('span',[_vm._v("Exportar Completo")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}])},[_c('span',[_vm._v("Exporta todos os lançamentos com os filtros na tela")])])],1)],1)],1),_c('vue-to-excel',{attrs:{"fetch":_vm.exportSimpleReport,"fields":_vm.simpleReportFields,"name":"relatorio_comissões.xls","worksheet":"Colaboradores"}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{staticClass:"d-flex align-center justify-space-between"},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-file-document-outline")]),_c('span',[_vm._v("Exportar Simplificado")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}])},[_c('span',[_vm._v("Exportação simples apenas de quem recebe comissão ou não.")])])],1)],1)],1)],1)],1)],1)],1),_c('commission-filter-component',{attrs:{"isOnlyManager":_vm.isOnlyManager},on:{"find":_vm.getAll}}),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('commission-table',{attrs:{"items":_vm.commissionList,"page":_vm.pagination,"loading":_vm.loadingTable,"selectedItems":_vm.selectedItems,"hasUpdateCommission":_vm.hasUpdateCommission,"hasAccessConcludeCommission":_vm.hasAccessConcludeCommission,"hasDeleteCommission":_vm.hasDeleteCommission},on:{"update":_vm.updateTable,"updateSelectedItems":_vm.handleSelectedItemsUpdate}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }