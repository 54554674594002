

































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbTooltipSlot } from 'bag-of-holding-library'
import Rules from '@/shared/rules/rules';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import MonthYearSelect from '@/shared/month-year-picker/month-year-select.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import TooltipIcon from '@/shared/tooltip-icon.component.vue';


@Component({
  name: 'ScoreDateComponent',
  components: {
    DatePickerField,
    MonthYearSelect,
    LabelSlot,
    TooltipIcon,
    VbTooltipSlot,
  },
})
export default class ScoreDateComponent extends Vue {
  @Prop({
    type: String,
    required: true,
  }) readjustmentPeriod!: string;

  @Prop({
    type: Boolean,
    required: true,
  }) unscheduledScore!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  }) isRetroactive!: boolean;

  rules: Rules = new Rules();

  applyReadJustmentPeriod() {
    this.$emit('applyReadJustmentPeriod', this.readjustmentPeriod)
  }
}
