



















































































import ProfessionalDataService from '@/domain/employees/service/professional-data.service';
import { VbSnackbarService } from 'bag-of-holding-library';
import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from 'bag-of-holding-library/src/slot/label-slot.component.vue';
import Commission from '@/domain/commission/entity/commission.entity';
import CommissionService from '@/domain/commission/service/commission.service';
import LoadingComponent from '@/shared/loading/loading.component.vue';
import booleanValuesOption from '../score/boolean-values-option';

@Component({
  components: {
    LabelSlot,
    LoadingComponent,
  },
})
export default class EmployeeCommissionComponent extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) checkAccessCommission!: boolean;

  @Prop({
    type: String,
    default: false,
  }) employeeId!: string;

  booleanValuesOption = booleanValuesOption;

  receiveCommission: boolean = false;

  saveReceiveCommissionLoading: boolean = false;

  commissions: Commission[] = [];

  page: number = 0;

  size: number = 3;

  totalPages: number = 1;

  loading: boolean = false;

  loadingListCommissions: boolean = false;

  created() {
    this.loading = true;
    Promise.all([
      this.loadReceiveCommission(),
      this.getAll(),
    ]).then(() => this.loading = false);
  }

  async getAll(): Promise<void> {
    this.loadingListCommissions = true;
    await CommissionService.getByEmployeeUsername(this.$route.params.username, this.page, this.size)
      .then(({ data }: any) => {
        this.commissions.push(...data.content.map(it => new Commission(it)));
        this.totalPages = data.totalPages;
      }).finally(() => {
        this.loadingListCommissions = false;
        this.page += 1;
      })
  }

  async loadReceiveCommission(): Promise<void> {
    await ProfessionalDataService.getReceiveCommissionByEmployeeId(this.employeeId)
      .then((receiveCommission: boolean) => {
        this.receiveCommission = receiveCommission;
      })
      .catch(VbSnackbarService.handleHttpError);
  }

  handleReceiveCommission() {
    this.saveReceiveCommissionLoading = true;
    ProfessionalDataService
      .updateReceiveCommission(this.employeeId,
        this.receiveCommission)
      .then(() => VbSnackbarService.showSuccess('Informação salva com sucesso!'))
      .catch((error) => {
        this.receiveCommission = !this.receiveCommission
        VbSnackbarService.showError(error.response.data.messages.toString());
      }).finally(() => {
        this.saveReceiveCommissionLoading = false;
      })
  }
}
