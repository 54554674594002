















import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbTooltipSlot } from 'bag-of-holding-library'
import moment from 'moment';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import MonthYearSelect from '@/shared/month-year-picker/month-year-select.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';


@Component({
  name: 'CommissionDateComponent',
  components: {
    DatePickerField,
    MonthYearSelect,
    LabelSlot,
    VbTooltipSlot,
  },
})
export default class CommissionDateComponent extends Vue {
  @Prop() isDisabledCompetence: boolean = false

  commissionPeriod: string = ''

  async created() {
    this.commissionPeriod = moment().format('MM/YYYY');
    this.$emit('commissionPeriod', this.commissionPeriod)
  }

  changeCommissionPeriod(period: string) {
    this.commissionPeriod = period
    this.$emit('commissionPeriod', this.commissionPeriod)
  }
}
