























































































































import {
  Vue,
  Component,
  Watch,
  Prop,
} from 'vue-property-decorator';
import Employee from '@/domain/employees/entity/employee.entity';

@Component

export default class ProfileTabsComponent extends Vue {
  @Prop() employee!: Employee;

  @Prop({
    type: Boolean,
    default: false,
  })
  isLoggedUserProfile!: boolean;

  @Prop() isAdministrator!: boolean;

  @Prop() accessProfessionalData!: boolean;

  @Prop() accessScore!: boolean;

  @Prop() accessPersonalData!: boolean;

  @Prop() accessEmployeeRemunaration!: boolean;

  @Prop() accessEmployeeActivity!: boolean;

  @Prop() accessSkillTab!: boolean;

  @Prop() accessPdiTab!: boolean;

  tab: number = 0;

  items: Array<string> = ['Nomenclaturas'];

  @Watch('tab')
  selectTab() {
    this.$emit('selectTab', this.tab);
  }

  @Watch('isLoggedUserProfile')
  private onChangeIsLoggedUserProfile() {
    if (this.isLoggedUserProfile) {
      if (this.$route.query.tab === 'career-data') {
        this.tab = 0;
      }
      if (this.$route.query.tab === 'personal-data') {
        this.tab = 1;
      }
      if (this.$route.query.tab === 'activities') {
        this.tab = 2;
      }
      if (this.$route.query.tab === 'employee-wages') {
        this.tab = 3;
      }
      if (this.$route.query.tab === 'vacation-payslip') {
        this.tab = 4;
      }
      if (this.$route.query.tab === 'skill-map') {
        this.tab = 6;
      }
    }
  }
}

