




















































































































import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import VueToExcel from 'vue-json-excel';
import CardTitle from '@/shared/card/card-title.component.vue';
import CommissionSimpleReport from '@/domain/commission/entity/commission-simple-report.entity';
import CommissionService from '@/domain/commission/service/commission.service';
import CommissionList from '@/domain/commission/entity/commission-list.entity';
import CommissionFilter from '@/domain/commission/entity/commission-filter.entity';
import AuthorityStore from '@/domain/security/authority.store';
import AuthorityEnum from '@/domain/security/authority.enum';
import Authority from '@/domain/security/authority.entity';
import CommissionTable from './commission-table.component.vue';
import CommissionFilterComponent from './commission-filter.component.vue';

@Component({
  components: {
    CardTitle,
    VueToExcel,
    CommissionTable,
    CommissionFilterComponent,
  },
})
export default class CommissionListComponent extends Vue {
  simpleReportResult: CommissionSimpleReport[] = [];

  loadingExport: boolean = false;

  hasAccessCreateCommission: boolean = false;

  hasAccessConcludeCommission: boolean = false;

  hasUpdateCommission: boolean = false;

  simpleReportFields: any = {
    Colaborador: 'employeeName',
    Gestor: 'managerName',
    Unidade: 'businessUnitName',
    Papel: 'assignmentName',
    Cargo: 'roleName',
    'Recebe Comissão': 'receiveCommissionFormat',
  }

  completeReportFiels: any = {
    Colaborador: 'employeeName',
    Gestor: 'managerName',
    Unidade: 'businessUnitName',
    Papel: 'assignmentName',
    Cargo: 'roleName',
    'Criado por': 'createdBy',
    'Criado em': 'createdDateFormatted',
    Documento: 'registration',
    Valor: 'value',
    Competencia: 'competenceFormatted',
    Moeda: 'currencySymbol',
    Status: 'concludedFormatted',
  }

  sortBy: Array<string> = ['competence'];

  sortDesc: boolean[] = [true];

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  commissionList: CommissionList[] = [];

  selectedItems: CommissionList[] = [];

  loadingTable: boolean = false;

  loading: boolean = false;

  filter: CommissionFilter = new CommissionFilter();

  exportCompleteData: CommissionList[] = [];

  hasDeleteCommission: boolean = false;

  isOnlyManager: boolean = false;

  created() {
    this.getAll();
    this.validateAuthoryties();
  }

  async getSimpleReport() {
    await CommissionService.getSimpleReport()
      .then((response: CommissionSimpleReport[]) => {
        this.simpleReportResult = response;
      }).catch((error) => {
        VbSnackbarService.handleHttpError(error);
      })
  }

  async exportSimpleReport() {
    this.loadingExport = true;
    await this.getSimpleReport();
    this.loadingExport = false;
    return this.simpleReportResult;
  }

  async setConcludedCommission() {
    const commissionIds = this.selectedItems.map(item => item.id);
    try {
      this.loading = true;
      await CommissionService.concludeCommissions(commissionIds);
      VbSnackbarService.showSuccess('Comissões concluídas com sucesso!');
    } catch (err) {
      VbSnackbarService.handleHttpError(err);
    }
    this.getAll();
    this.loading = false;
    this.selectedItems = [];
  }

  async exportCompleteReport() {
    this.loadingExport = true;
    await this.getCompleteReportData();
    this.loadingExport = false;
    return this.exportCompleteData;
  }

  async getCompleteReportData() {
    this.exportCompleteData = [];
    const pagination = ({
      ...this.pagination,
      itemsPerPage: 100,
      page: 1,
    });

    do {
      // eslint-disable-next-line no-await-in-loop
      await CommissionService.getAll(this.filter, pagination.page, pagination.itemsPerPage, true)
        .then(({ data }: any) => {
          const content = data.content.map((it: any) => new CommissionList(it));
          this.exportCompleteData.push(...content);
          pagination.total = data.totalElements;
          pagination.page += 1;
        })
    } while (this.exportCompleteData.length < pagination.total);
  }

  validateAuthoryties() {
    AuthorityStore.fetchedAuthority().then((authority: Authority) => {
      this.hasAccessCreateCommission = authority
        .hasAuthorities([AuthorityEnum.CREATE_COMMISSION]);
      this.hasUpdateCommission = authority
        .hasAuthorities([AuthorityEnum.UPDATE_COMMISSION]);
      this.hasAccessConcludeCommission = authority
        .hasAuthorities([AuthorityEnum.CONCLUDE_COMMISSION]);
      this.hasDeleteCommission = authority
        .hasAuthorities([AuthorityEnum.DELETE_COMMISSION]);
      this.isOnlyManager = authority.isOnlyManager();
    })
  }

  createCommission() {
    this.$router.push({ name: 'Criar Comissões' });
  }

  updateTable(pagination: any) {
    if (this.pagination.itemsPerPage < pagination.itemsPerPage) {
      pagination.page = 1;
    }
    this.pagination = pagination;
    this.getAll();
  }

  getAll(filter: CommissionFilter = this.filter) {
    this.loadingTable = true;
    this.filter = filter;
    CommissionService.getAll(filter, this.pagination.page, this.pagination.itemsPerPage)
      .then(({ data }) => {
        this.commissionList = data.content.map((it: any) => new CommissionList(it));
        this.pagination.total = data.totalElements || 0;
        this.pagination.pageCount = data.totalPages;
      }).catch(VbSnackbarService.handleHttpError)
      .finally(() => this.loadingTable = false);
  }

  handleSelectedItemsUpdate(items: CommissionList[]) {
    this.selectedItems = items;
  }
}
