export default class CommissionSimpleReport {
  employeeName: string;

  managerName: string;

  businessUnitName: string;

  assignmentName: string;

  roleName: string;

  receiveCommission: boolean;

  constructor(obj: any = {}) {
    this.employeeName = obj.employeeName || '';
    this.managerName = obj.managerName || '';
    this.businessUnitName = obj.businessUnitName || '';
    this.assignmentName = obj.assignmentName || '';
    this.roleName = obj.roleName || '';
    this.receiveCommission = obj.receiveCommission;
  }

  get receiveCommissionFormat() {
    return this.receiveCommission ? 'Sim' : 'Não'
  }
}
