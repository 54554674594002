

















































































































































































































































































































































import {
  Vue, Component,
} from 'vue-property-decorator';
import {
  CurrencyType,
  FixedAgreement,
  maxDayCreateScoreInMonthNow,
  scoreSource,
} from '@/views/employees/profile/tab-components/score/score-utils';
import { VbSnackbarService } from 'bag-of-holding-library';
import { Currency } from '@/domain/employees/entity/professional-data.entity';
import moment from 'moment';
import DateFormat from '@/shared/utils/date-format';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import MonthYearSelect from '@/shared/month-year-picker/month-year-select.component.vue';
import CardTitle from '@/shared/card/card-title.component.vue';
import Agreement from '@/domain/employees/entity/agreement.entity';
import ScoreMessage from '@/views/score/score-message.component.vue';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import CareerService from '@/domain/employees/service/career.service';
import TooltipIcon from '@/shared/tooltip-icon.component.vue';
import ScoreDependentBenefits from '@/domain/employees/entity/score-dependent-benefits';
import HealthPlanService from '@/domain/health-plan/service/health-plan.service';
import HealthPlanDTO from '@/domain/health-plan/entity/health-plan-DTO';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import ScoreDescriptionRead from '@/views/score/read/score-description-read.component.vue';
import DrdService from '@/domain/drd/service/drd.service';
import Assignment from '@/domain/drd/entity/assignment.entity';
import AgreementComponent from '@/views/employees/profile/tab-components/score/agreement.component.vue';
import DependentBenefitsListComponent from '@/views/employees/profile/tab-components/score/dependent-list.component.vue';
import ScoreDateComponent from '@/views/employees/profile/tab-components/score/score-date.component.vue';
import ScoreBenefitsEmployeeComponent from '@/views/employees/profile/tab-components/score/score-benefits-employee.component.vue';
import Rules from '@/shared/rules/rules';
import CurrencySelect from '@/shared/currency/select/currency-select.component.vue';
import InitialScore from '@/domain/initial-score/entity/initial-score.entity';
import InitialScoreService from '@/domain/initial-score/service/initial-score.service';
import BusinessUnitSelect from '@/shared/business-unit/business-unit-select.component.vue';
import OperationModalitySelect from '@/shared/operation-modality/operation-modality-select.component.vue';
import OperationModality from '@/domain/operation-modality/entity/operation-modality.entity';
import AddressComponent from '@/shared/address/address.component.vue';
import WorkplaceSelect from '@/shared/workplace/workplace-select.component.vue';
import ResultCenter from '@/domain/result-center/entity/result-center.entity';
import ResultCenterSelect from '@/shared/result-center/result-center-select.component.vue';
import ResultCenterService from '@/domain/result-center/service/result-center.service';
import ManagerSelect from '@/shared/manager/manager-select.component.vue';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import PhoneComponent from '@/shared/phone/phone.component.vue';
import EmailInput from '@/shared/email/email-input.component.vue';
import EmployeeSelect from '@/shared/employee/employee-select.vue';
import ContractTypes from '@/shared/contract-type/contract-type.json';
import PdfContentComponent from './pdf-content.component.vue';
import BusinessUnitSelectOptions from './business-unit-select-options.json';
import OperationalSystemSelect from './components/operational-system-select.component.vue';
import ProjectInput from './components/project-input.component.vue';
import BankAccountDocumentBusinessUnitSelectOptions from './bank-account-document-business-unit-select.json';
import ExportDocumentsMenu from './components/export-documents-menu.component.vue';
import InitialScoreObservationComponent from './components/initial-score-observation-component.vue';
import booleanValuesOption from '../employees/profile/tab-components/score/boolean-values-option';


@Component({
  components: {
    DatePickerField,
    LabelSlot,
    MonthYearSelect,
    CardTitle,
    AgreementComponent,
    TooltipIcon,
    DependentBenefitsListComponent,
    ScoreMessage,
    ModalWarning,
    ScoreDescriptionRead,
    ScoreDateComponent,
    ScoreBenefitsEmployeeComponent,
    PdfContentComponent,
    CurrencySelect,
    BusinessUnitSelect,
    OperationModalitySelect,
    AddressComponent,
    WorkplaceSelect,
    ResultCenterSelect,
    ManagerSelect,
    PhoneComponent,
    OperationalSystemSelect,
    EmailInput,
    ProjectInput,
    EmployeeSelect,
    ExportDocumentsMenu,
    InitialScoreObservationComponent,
  },
  filters: {
    formatDate(value: string) {
      return DateFormat.timestampToLocal(value.split('T')[0]);
    },
  },
})

export default class NewInitialScoreComponent extends Vue {
  score: InitialScore = InitialScore.of({});

  roles: UpdateAtribute[] = [];

  fixedAgreementList: Agreement[] = [];

  variableAgreementList: Agreement[] = [];

  totalAgreementList: Agreement[] = [];

  valid: Boolean = false;

  refs: any = this.$refs

  healthPLanList:HealthPlanDTO[] = [];

  totalAgreement?: Agreement;

  seniorities: any = {};

  rules: Rules = new Rules();

  request = {
    seniorities: [
      {
        value: 'Estagiário',
        disabled: true,
      },
      {
        value: 'Trainee',
        disabled: false,
      },
      {
        value: 'Junior',
        disabled: true,
      },
      {
        value: 'Pleno',
        disabled: false,
      },
      {
        value: 'Sênior',
        disabled: false,
      },
    ],
  }

  loading: boolean = false;

  editPrevious: boolean = false;

  dateFullFormat: string = 'YYYY-MM-DD';

  dateMothYearFormat: string = 'MM/YYYY';

  loadingSaveButton: boolean = false;

  enableScoreDetailsMessage: boolean = false;

  scoreSource = scoreSource;

  maxDayCreateScoreInMonthNow: number = maxDayCreateScoreInMonthNow();

  FixedAgreementEnum = FixedAgreement;

  assignments: Assignment[] = [];

  currentBusinessUnitCode: number = 0;

  scoreIsRetroactive: boolean = false;

  roleOrSeniorityIsUpdated: boolean = false;

  employeeName: string = '';

  businessUnitOptions = BusinessUnitSelectOptions

  businessUnit: any = {}

  workJourne: string = '44'

  startDate: string = '';

  alreadyExport: boolean = false;

  currency: Currency = {
    name: 'BRL',
    description: 'Real',
    symbol: 'R$',
  };

  contractTypes = ContractTypes;

  operationModality: string = '';

  disabledOperationModality: boolean = false;

  resultCenters: ResultCenter[] = [];

  lastBusinessUnit: string = '';

  loadingResultCenter: boolean = false;

  countryIsBrazil: boolean = false;

  locale: string = 'pt-br';

  businessUnitBankAccountDocument: any = {};

  businessUnitBankAccountDocumentOptions = BankAccountDocumentBusinessUnitSelectOptions;

  booleanValuesOption = booleanValuesOption;

  async created() {
    this.$destroy();
    this.loading = true;
    this.score.agreementList = Agreement.createInitialAgreements();
    this.setListOfAgreement();
    await this.getInitialData();
    this.setScoreInitialData();
  }

  setScoreInitialData() {
    this.score.unscheduledScore = false;
    this.currency = this.score.currency;
    this.setCompetence();
    this.setCountDate();
  }

  setCompetence() {
    const dayOfMonth = moment().date();
    if (dayOfMonth > this.maxDayCreateScoreInMonthNow) {
      this.score.readjustmentPeriod = moment().add(1, 'months').format(this.dateMothYearFormat);
      return;
    }
    this.score.readjustmentPeriod = moment().format(this.dateMothYearFormat);
  }

  setCountDate() {
    this.score.countDate = moment().format(this.dateFullFormat);
  }

  applyReadJustmentPeriod(value: string) {
    this.score.readjustmentPeriod = value;
  }

  clearNextCountDate() {
    this.score.nextCountDate = '';
  }

  save() {
    const { form }: any = this.refs;
    if (!form.validate()) {
      VbSnackbarService.showError('Por favor, preencha todos os campos obrigatórios!');
      return;
    }
    this.loadingSaveButton = true;
    this.score.total();
    this.createInitialScore(this.score.toRequest());
  }

  createInitialScore(score: InitialScore) {
    score.agreementList.forEach((agreement) => {
      agreement.previousAgreement = agreement.newAgreement
    })
    InitialScoreService.create(score)
      .then(() => {
        VbSnackbarService.showSuccess('Acordo inicial criado com sucesso!');
        this.$router.push('/initial-score')
      })
      .catch((err: any) => {
        VbSnackbarService.handleHttpError(err);
      }).finally(() => {
        this.loadingSaveButton = false;
      });
  }

  setListOfAgreement() {
    this.fixedAgreementList = [];
    this.variableAgreementList = [];
    this.totalAgreementList = [];

    const fixedValuesAgreement = [
      this.FixedAgreementEnum.SALARY_IN_CLT,
      this.FixedAgreementEnum.DUCZ,
      this.FixedAgreementEnum.DUCZ_SYNDICATE_FREE,
      this.FixedAgreementEnum.EDUCATION_SUBSIDY_ASSISTANCE,
    ];
    const fixedCareerAgreement = [
      this.FixedAgreementEnum.ASSIGNMENT,
      this.FixedAgreementEnum.ROLE,
      this.FixedAgreementEnum.SENIORITY,
    ];
    const fixedAgreement = [
      ...fixedCareerAgreement,
      ...fixedValuesAgreement,
      this.FixedAgreementEnum.TOTAL,
    ];

    fixedAgreement.forEach((descrition) => {
      this.score.agreementList.forEach((it) => {
        if (it.description === descrition && descrition !== this.FixedAgreementEnum.TOTAL) {
          this.fixedAgreementList.push(it);
        }
      })
    })

    this.variableAgreementList = this.score.agreementList
      .filter(it => !fixedAgreement.some(description => description === it.description));

    this.variableAgreementList.forEach((variableAgreement) => {
      variableAgreement.previousAgreement = variableAgreement.newAgreement;
      variableAgreement.newAgreement = '';
    })

    this.totalAgreementList = this.score.agreementList
      .filter(it => it.description === this.FixedAgreementEnum.TOTAL);
  }

  updateHealthPlanEmployee(value: boolean) {
    this.score.healthPlanEmployee = value;
    if (!value) {
      this.score.benefitObservation = '';
      this.score.healthPlanEmployeePayedByDB1 = false;
      this.score.healthPlan.healthPlanType = '';
    }
  }

  updateDentalPlanEmployee(value: boolean) {
    this.score.dentalPlanEmployee = value;
    if (!value) {
      this.score.dentalPlanEmployeePayedByDB1 = false;
    }
  }

  addDependent() {
    const dependent = ScoreDependentBenefits.of({})
    this.score.dependentBenefits.push(dependent)
  }

  addNewAgreement() {
    const total = this.score.agreementList.pop();
    this.score.agreementList.push(Agreement.of({ previousAgreement: 'NOT_EXISTS' }));
    if (total !== undefined) {
      this.score.agreementList.push(total);
    }
    this.setListOfAgreement();
  }

  removeAgreement(item: Agreement) {
    const index = this.score.agreementList.indexOf(item);
    this.score.agreementList.splice(index, 1);
    this.setListOfAgreement();
    this.updateTotal();
  }

  removeDependent(item: ScoreDependentBenefits) {
    const index = this.score.dependentBenefits.indexOf(item);
    this.score.dependentBenefits.splice(index, 1);
  }

  updateTotal() {
    this.score.total();
  }

  healthPLan(type: string): void {
    this.healthPLanList.forEach((it) => {
      if (it.healthPlanType === type) {
        this.score.healthPlan = HealthPlanDTO.of({});
        this.score.healthPlan = it;
      }
    })
  }

  updateHealthPlanEmployeePayedByDB1(healthPlanPayedByDB1: boolean): void {
    this.score.healthPlanEmployeePayedByDB1 = healthPlanPayedByDB1;
    if (healthPlanPayedByDB1) {
      this.healthPLan('REGION_INFIRMARY');
      return;
    }
    this.score.healthPlan.healthPlanType = '';
  }

  private setTotalAgreement(): void {
    if (!this.score) return;
    this.totalAgreement = this.score.agreementList
      .find((agreement: Agreement) => agreement.description === this.FixedAgreementEnum.TOTAL);
    this.score.agreementList = this.score.agreementList
      .filter((agreement: Agreement) => agreement.description !== this.FixedAgreementEnum.TOTAL);
  }

  getHealthPlanList() {
    HealthPlanService.getHealthPLanList().then((resp) => {
      this.healthPLanList = HealthPlanDTO.ofList(resp)
    })
  }

  getRoles() {
    CareerService.getRoles().then((resp: any) => this.roles = resp.data);
  }

  getSeniorities() {
    CareerService.getSeniority().then((resp: any) => {
      this.seniorities = resp.data;
      resp.data.forEach((respSeniority) => {
        this.request.seniorities.push(
          {
            value: respSeniority.name,
            disabled: false,
          },
        )
      });
    });
  }

  getAssignments() {
    DrdService.getAssigments().then((response: Assignment[]) => this.assignments = response);
  }

  async getInitialData() {
    Promise.all([
      this.getRoles(),
      this.getHealthPlanList(),
      this.getSeniorities(),
      this.getAssignments(),
    ]).finally(() => {
      this.loading = false;
    });
  }

  get senioritySorted() {
    return this.request.seniorities.sort((seniority1, seniority2) => {
      if (seniority1.value < seniority2.value) return -1;
      if (seniority1.value > seniority2.value) return 1;
      return 0;
    })
  }

  get scoreCountDate() {
    return moment(this.score.countDate).format('DD/MM/YYYY');
  }

  back() {
    window.history.go(-1)
  }

  updateDentalPlanEmployeePayedByDB1(value: boolean) {
    this.score.dentalPlanEmployeePayedByDB1 = value;
  }

  updateLifeInsuranceEmployee(value: boolean) {
    this.score.lifeInsuranceEmployee = value;
  }

  updateBenefitObservation(value: string) {
    this.score.benefitObservation = value;
  }

  get ducz() {
    if (this.score.containsDuczSyndicate) {
      return this.fixedAgreementList
        .filter(it => it.description === this.FixedAgreementEnum.DUCZ)[0].newAgreementValue
        + this.fixedAgreementList
          .filter(it => it.description === this.FixedAgreementEnum.DUCZ_SYNDICATE_FREE)[0]
          .newAgreementValue
    }
    return this.fixedAgreementList
      .filter(it => it.description === this.FixedAgreementEnum.DUCZ)[0].newAgreementValue
  }

  get educationSubsidy() {
    return this.fixedAgreementList
      .filter(it => it.description === this.FixedAgreementEnum.EDUCATION_SUBSIDY_ASSISTANCE)[0]
      .newAgreementValue
  }

  get salary() {
    return this.fixedAgreementList
      .filter(it => it.description === this.FixedAgreementEnum.SALARY_IN_CLT)[0].newAgreementValue
  }

  get roleName() {
    return this.fixedAgreementList
      .filter(it => it.description === this.FixedAgreementEnum.ROLE)[0].newAgreement
  }

  get seniority() {
    return this.fixedAgreementList
      .filter(it => it.description === this.FixedAgreementEnum.SENIORITY)[0].newAgreement
  }

  updateCurrency(currency: Currency) {
    this.score.currency = currency;
    this.currency = currency;
    this.updateAgreement();
  }

  updateContractType(contractType: string) {
    this.score.contractType = contractType;
    this.updateAgreement();
  }

  updateAgreement() {
    if (
      !this.score.containsDuczSyndicate
      && this.score.currency.name === CurrencyType.BRL
      && (this.score.contractType === 'CLT' || this.score.contractType === 'PJ')
    ) {
      this.score.addDuczSyndicate();
      this.setListOfAgreement();
      return;
    }
    if (this.score.containsDuczSyndicate && (this.score.contractType === 'YOUNG_APPRENTICE' || this.score.contractType === 'INTERNSHIP')) {
      this.score.removeDuczSyndicate();
      this.setListOfAgreement();
      return;
    }
    if (this.score.containsDuczSyndicate
    && (this.score.contractType === 'CLT' || this.score.contractType === 'PJ')
    && this.score.currency.name !== CurrencyType.BRL) {
      this.score.removeDuczSyndicate();
      this.setListOfAgreement();
    }
  }

  changeOperationModality(operationModalitySelected: OperationModality) {
    this.score.operationModality = operationModalitySelected;
  }

  updateAddressFields(field: string, value: any) {
    this.score.address[field] = value;
  }

  updateWorkplace(workplaceSelected: any) {
    this.score.workplace = workplaceSelected.id;
    if (workplaceSelected.defaultOperationModality) {
      this.score.operationModality = workplaceSelected.defaultOperationModality;
      this.disabledOperationModality = true;
      return
    }
    this.score.operationModality = new OperationModality();
  }

  getResultCenters() {
    this.loadingResultCenter = true;
    ResultCenterService.getResultCentersByBusinessUnitId(this.score.businessUnitId)
      .then(resp => this.resultCenters = resp)
      .finally(() => this.loadingResultCenter = false);
  }

  updateBusinessUnit(businessUnit: UpdateAtribute) {
    if (this.score.businessUnitId === businessUnit.id) return;
    this.score.businessUnitId = businessUnit.id!;
    this.lastBusinessUnit = businessUnit.id!;
    this.currentBusinessUnitCode = Number(businessUnit.code);
    this.getResultCenters();
  }

  get noDataTextResultCenter(): string {
    if (!this.score.businessUnitId) return 'Por favor, informe a unidade';
    return this.resultCenters.length === 0 ? 'Nenhum centro de custo encontrado' : '';
  }

  updateManager(manager: EmployeeSummary) {
    this.score.manager = manager;
    this.updateBusinessUnit(manager.businessUnit);
  }

  get contractType() {
    return this.contractTypes.filter(it => it.name === this.score.contractType)[0];
  }
}
