import moment from 'moment';

export default class Commission {
  employeeId: string;

  employeeName: string;

  value: number;

  currencySymbol: string;

  createdBy: string;

  competence: string;

  constructor(obj: any = {}) {
    this.employeeId = obj.employeeId || '';
    this.employeeName = obj.employeeName || '';
    this.value = obj.value;
    this.currencySymbol = obj.currencySymbol || '';
    this.createdBy = obj.createdBy || '';
    this.competence = obj.competence || '';
  }

  get competenceFormatted() {
    moment.locale('pt-br')
    return moment(this.competence).format('MMMM, YYYY')
  }

  get createdByNameFormatted() {
    const nameSplited = this.createdBy.split(' ');
    return `${nameSplited[0]} ${nameSplited[nameSplited.length - 1]}`
  }
}
