





































import { Vue, Component, Prop } from 'vue-property-decorator';
import DateFormat from '../utils/date-format';

@Component
export default class MonthYearMenuSelect extends Vue {
  @Prop({
    type: String,
    default: '',
  }) value!: string;

  menu: boolean = false;

  input(value: string) {
    this.menu = !this.menu;
    this.$emit('input', value)
  }

  clear() {
    this.$emit('input', '')
  }

  get competenceFormat() {
    return DateFormat.monthNumberYear(this.value);
  }
}
