import { render, staticRenderFns } from "./admission-date-anniversary.view.vue?vue&type=template&id=1202fb68&scoped=true&"
import script from "./admission-date-anniversary.view.vue?vue&type=script&lang=ts&"
export * from "./admission-date-anniversary.view.vue?vue&type=script&lang=ts&"
import style0 from "./admission-date-anniversary.view.vue?vue&type=style&index=0&id=1202fb68&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1202fb68",
  null
  
)

export default component.exports