import CurrencyFormater from '@/shared/utils/currency-formater';
import moment from 'moment';

export default class CommissionList {
  id: string

  employeeName: string;

  createdBy: string;

  value: number;

  competence: string;

  currencySymbol: string;

  concluded: boolean;

  loadingDelete: boolean = false;

  managerName: string;

  businessUnitName: string;

  roleName: string;

  assignmentName: string;

  createdDate: string;

  registration: string;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.employeeName = obj.employeeName || '';
    this.createdBy = obj.createdBy || '';
    this.value = obj.value;
    this.competence = obj.competence || '';
    this.currencySymbol = obj.currencySymbol || '';
    this.concluded = obj.concluded;
    this.loadingDelete = obj.loadingDelete;
    this.managerName = obj.managerName || '';
    this.businessUnitName = obj.businessUnitName || '';
    this.roleName = obj.roleName || '';
    this.assignmentName = obj.assignmentName || '';
    this.createdDate = obj.createdDate || '';
    this.registration = obj.registration || '';
  }

  get isSelectable() {
    return !this.concluded
  }

  get valueFormatted() {
    return CurrencyFormater.formatedValue(this.value);
  }

  get competenceFormatted() {
    return moment(this.competence, 'YYYY-MM').format('MM/YYYY');
  }

  get createdDateFormatted() {
    return moment(this.createdDate).format('DD/MM/YYYY');
  }

  get concludedFormatted() {
    return this.concluded ? 'Concluído' : 'Não Concluído';
  }
}
