import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';

export default class EmployeeSummary {
  id: string;

  name: string;

  businessUnit: UpdateAtribute;

  managerId: string;

  currencySymbol: string;

  private constructor(obj: any) {
    this.id = obj.id;
    this.name = obj.name;
    this.businessUnit = obj.businessUnit || '';
    this.managerId = obj.managerId;
    this.currencySymbol = obj.currencySymbol || '';
  }

  public static of(obj: any): EmployeeSummary {
    return new EmployeeSummary(obj || {});
  }

  public static ofList(obj: any): EmployeeSummary[] {
    return obj.map(this.of);
  }
}
