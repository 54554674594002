








import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class Card extends Vue {
  @Prop({
    required: false,
    default: false,
  })
  private transparentBackground!: boolean;
}
