import CurrencyFormat from '@/views/employees/profile/tab-components/score/currency-formatter';
import { FixedAgreement } from '@/views/employees/profile/tab-components/score/score-utils';

export default class Agreement {
  id: string;

  description: string;

  previousAgreement: string;

  newAgreement: string;

  readjustment: number;

  numericField: boolean = true;

  validUntil: string;

  constructor(obj: any) {
    this.id = obj.id || null;
    this.description = obj.description || '';
    this.previousAgreement = obj.previousAgreement || '';
    this.newAgreement = obj.newAgreement || '';
    this.readjustment = parseFloat(obj.readjustment) || 0;
    this.numericField = obj.numericField === undefined ? true : obj.numericField;
    this.validUntil = obj.validUntil || ''
  }

  public static of(obj: any): Agreement {
    return new Agreement(obj || {})
  }

  public static ofList(obj: any[]): Agreement[] {
    return obj.map(this.of);
  }

  public static ofPrevious(agreement: Agreement): Agreement {
    const newAgreement = new Agreement(agreement);
    newAgreement.id = '';
    newAgreement.previousAgreement = agreement.newAgreement;
    if (newAgreement.numericField) {
      newAgreement.newAgreement = '';
    }
    return newAgreement;
  }

  public static createInitialAgreements(): Agreement[] {
    return Agreement.ofList([
      {
        description: FixedAgreement.ASSIGNMENT,
        numericField: false,
        validUntil: null,
      },
      {
        description: FixedAgreement.ROLE,
        numericField: false,
        validUntil: null,
      },
      {
        description: FixedAgreement.SENIORITY,
        numericField: false,
        validUntil: null,
      },
      {
        description: FixedAgreement.SALARY_IN_CLT,
        numericField: true,
        validUntil: null,
      },
      {
        description: FixedAgreement.DUCZ,
        numericField: true,
        validUntil: null,
      },
      {
        description: FixedAgreement.DUCZ_SYNDICATE_FREE,
        numericField: true,
        validUntil: null,
      },
      {
        description: FixedAgreement.EDUCATION_SUBSIDY_ASSISTANCE,
        numericField: true,
        validUntil: null,
      },
      {
        description: FixedAgreement.TOTAL,
        numericField: true,
        validUntil: null,
      },
    ])
  }

  isRole(): boolean {
    if (this.description === FixedAgreement.ROLE) this.numericField = false;
    return this.description === FixedAgreement.ROLE;
  }

  isSeniority(): boolean {
    if (this.description === FixedAgreement.SENIORITY) this.numericField = false;
    return this.description === FixedAgreement.SENIORITY;
  }

  isAssignment(): boolean {
    return this.description === FixedAgreement.ASSIGNMENT;
  }

  get isTotal(): boolean {
    return this.description === FixedAgreement.TOTAL;
  }

  get previousAgreementValue(): number {
    if (typeof this.previousAgreement === 'number') return this.previousAgreement;
    if (this.previousAgreement.match('[a-zA-Z]')) return 0;
    const value = CurrencyFormat.toNumber(this.previousAgreement)
    return typeof (value) === 'number' ? value : 0;
  }

  get newAgreementValue(): number {
    if (this.newAgreement.match('[a-zA-Z]')) return 0;
    const value = CurrencyFormat.toNumber(this.newAgreement)
    return typeof (value) === 'number' ? value : 0;
  }

  private get calculatePersentReadjustment(): number {
    return this.previousAgreementValue !== 0
      ? ((this.newAgreementValue - this.previousAgreementValue) / this.previousAgreementValue) * 100
      : 0;
  }

  get persentReadjustment(): string {
    const value = this.calculatePersentReadjustment
    this.readjustment = parseFloat(value.toFixed(2))
    return value <= 0 ? '' : value.toFixed(2).toString().concat('%')
  }

  updatePreviousAgreement(value: number): void {
    if (value === null) {
      this.previousAgreement = '';
      return;
    }
    this.previousAgreement = value.toString();
  }

  updateNewAgreement(value: number): void {
    if (value === null) {
      this.newAgreement = '';
      return;
    }
    this.newAgreement = value.toString();
  }
}
