





































































































import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import AuthorityStore from '@/domain/security/authority.store';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import CommissionService from '@/domain/commission/service/commission.service';
import Commission from '@/domain/commission/entity/commission.entity';
import CurrencyField from '@/shared/currency-field.component.vue';
import Loading from '@/shared/loading/loading.component.vue';
import CardTitle from '@/shared/card/card-title.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import debounce from '@/shared/utils/debounce';
import MobileHandler from '@/shared/utils/mobile-handler';
import CommissionDateComponent from '../commission-date.component.vue';

@Component({
  name: 'CommissionCreateComponent',
  components: {
    CurrencyField,
    Loading,
    CardTitle,
    CommissionDateComponent,
    LabelSlot,
  },
})
export default class CommissionCreateComponent extends Vue {
  loading: boolean = false;

  commissionPeriod: string = '';

  commissions: Commission[] = [];

  dateMothYearFormat: string = 'YYYY-MM';

  employees: EmployeeSummary[] = [];

  employee: EmployeeSummary = EmployeeSummary.of({});

  lastFilterEmployee: string = '';

  currency: string = '';

  searchEmployeeInput: string = '';

  loadingSearchEmployee: boolean = false;

  isMobile: boolean = false;

  isManager: boolean = false;

  isPersonalDepartment: boolean = false;

  get isDisabledCompetence(): boolean {
    return (this.isManager && !this.isPersonalDepartment)
  }

  async mounted() {
    const subordinates = await CommissionService.getSubordinatesReceiveCommission();
    this.commissions = subordinates.map(subordinate => new Commission({
      employeeId: subordinate.id,
      employeeName: subordinate.employeeName,
      currency: subordinate.currencySymbol,
      value: 0,
    }));
  }

  created() {
    this.verifyAuthorities();
  }

  handleIsMobile() {
    this.isMobile = MobileHandler.isLessThan(600);
  }

  changeCommissionPeriod(period: string) {
    this.commissionPeriod = period
  }

  async save() {
    if (!this.commissionPeriod) {
      VbSnackbarService.showError('Competência é obrigatório')
      return;
    }
    this.loading = true;
    const [month, year] = this.commissionPeriod.split('/');
    this.commissionPeriod = `${year}-${month}`;
    try {
      await CommissionService.createCommission(this.commissionPeriod, this.commissions);
      VbSnackbarService.showSuccess('Dados salvos com sucesso!');
      this.close();
    } catch (err) {
      VbSnackbarService.handleHttpError(err);
    } finally {
      this.loading = false;
    }
  }

  verifyAuthorities() {
    AuthorityStore.fetchedAuthority()
      .then((authorities) => {
        this.isManager = authorities.isManager();
        this.isPersonalDepartment = authorities.isPersonalDepartment();
      })
  }

  findEmployeesByName(value: string) {
    if ((this.lastFilterEmployee !== value) && value) {
      this.lastFilterEmployee = value;
      if (value.length > 2) {
        const employeeFinded = this.employees
          .find(it => it.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
        if (employeeFinded) return;
        this.loadingSearchEmployee = true;
        EmployeeService.findEmployeesByName(value).then((resp: EmployeeSummary[]) => {
          this.employees.push(...resp)
        }).finally(() => this.loadingSearchEmployee = false);
      }
    }
  }

  findEmployeeNyNameDebounce(value: string) {
    debounce.debounce(() => this.findEmployeesByName(value))
  }

  setEmployees(resp: EmployeeSummary[]) {
    this.employees = resp;
  }

  includeEmployee(resp: EmployeeSummary) {
    const alreadyExists = this.commissions.some(it => it.employeeId === resp.id);
    if (alreadyExists) {
      VbSnackbarService.showError('Colaborador(a) já adicionado');
      this.clearFindEmployeeInput();
      return;
    }
    this.commissions.push(new Commission({
      employeeId: resp.id,
      employeeName: resp.name,
      value: 0,
      currency: resp.currencySymbol,
    }));
    this.clearFindEmployeeInput();
  }

  clearFindEmployeeInput() {
    this.employee = EmployeeSummary.of({});
    this.searchEmployeeInput = '';
  }

  removeCommission(item: Commission) {
    this.commissions = this.commissions
      .filter(el => el.employeeId!! !== item.employeeId!!)
  }

  close() {
    this.$router.push({
      path: '/commissions',
    })
  }
}
