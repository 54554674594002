import Agreement from '@/domain/employees/entity/agreement.entity';
import { Currency } from '@/domain/employees/entity/professional-data.entity';
import ScoreDependentBenefits from '@/domain/employees/entity/score-dependent-benefits';
import HealthPlanDTO from '@/domain/health-plan/entity/health-plan-DTO';
import DateFormat from 'bag-of-holding-library/src/utils/date-format';
import moment from 'moment';
import { FixedAgreement } from '@/views/employees/profile/tab-components/score/score-utils';
import OperationModality from '@/domain/operation-modality/entity/operation-modality.entity';
import Address from '@/domain/address/address.entity';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';

export default class InitialScore {
  id: string;

  employeeName: string;

  businessUnitId: string;

  contractType: string;

  workJourney: string;

  admissionDate: string;

  countDate: string;

  nextCountDate: string;

  readjustmentPeriod: string;

  healthPlanEmployee: boolean;

  dentalPlanEmployee: boolean;

  lifeInsuranceEmployee: boolean;

  agreementList: Agreement[];

  healthPlanEmployeePayedByDB1: boolean;

  dentalPlanEmployeePayedByDB1: boolean;

  dependentBenefits: ScoreDependentBenefits[];

  status: string;

  benefitObservation: string;

  healthPlan: HealthPlanDTO;

  observation: string;

  unscheduledScore: boolean = false;

  registration?: string;

  currency!: Currency;

  operationModality: OperationModality;

  address: Address;

  phone: string;

  project: string;

  operationalSystem: string;

  resultCenterId: string;

  workplace: string;

  manager: EmployeeSummary;

  personalEmail: string;

  godfather: EmployeeSummary;

  observationTemplate: string;

  receiveCommission: boolean;

  constructor(obj: any) {
    this.id = obj.id || '';
    this.employeeName = obj.employeeName || '';
    this.businessUnitId = obj.businessUnitId || '';
    this.contractType = obj.contractType || '';
    this.workJourney = obj.workJourney || '44';
    this.admissionDate = obj.admissionDate || '';
    this.countDate = obj.countDate || '';
    this.nextCountDate = obj.nextCountDate || '';
    this.readjustmentPeriod = this.formatYearMonth(obj.readjustmentPeriod);
    this.healthPlanEmployee = obj.healthPlanEmployee || false;
    this.dentalPlanEmployee = obj.dentalPlanEmployee || false;
    this.lifeInsuranceEmployee = obj.lifeInsuranceEmployee || false;
    this.agreementList = this.createListeAgreement(obj.agreementList) || [];
    this.healthPlanEmployeePayedByDB1 = obj.healthPlanEmployeePayedByDB1 || false;
    this.dentalPlanEmployeePayedByDB1 = obj.dentalPlanEmployeePayedByDB1 || false;
    this.status = obj.status
    this.dependentBenefits = obj.dependentBenefits
      ? ScoreDependentBenefits.ofList(obj.dependentBenefits)
      : []
    this.benefitObservation = obj.benefitObservation || ''
    this.healthPlan = this.createHealthPlan(obj.healthPlan);
    this.observation = obj.observation || '';
    this.unscheduledScore = obj.unscheduledScore || false;
    this.registration = obj.registration || '';
    this.currency = obj.currency || {
      name: 'BRL',
      description: 'Real',
      symbol: 'R$',
    };
    this.operationModality = obj.operationModality
      ? new OperationModality(obj.operationModality) : new OperationModality();
    this.address = obj.address ? new Address(obj.address) : new Address();
    this.phone = obj.phone || '';
    this.project = obj.project || '';
    this.operationalSystem = obj.operationalSystem || '';
    this.resultCenterId = obj.resultCenterId || '';
    this.workplace = obj.workplace || '';
    this.manager = obj.manager ? EmployeeSummary.of(obj.manager) : EmployeeSummary.of({});
    this.personalEmail = obj.personalEmail || '';
    this.godfather = obj.godfather ? EmployeeSummary.of(obj.godfather) : EmployeeSummary.of({});
    this.observationTemplate = obj.observationTemplate || '';
    this.receiveCommission = obj.receiveCommission;
  }

  createListeAgreement(agreementList: any[]): Agreement[] {
    if (!agreementList || agreementList.length === 0) return Agreement.createInitialAgreements();
    return Agreement.ofList(agreementList)
  }

  public static of(obj: any): InitialScore {
    return new InitialScore(obj || {})
  }

  public static ofList(obj: any[]): InitialScore[] {
    return obj.map(this.of);
  }

  private createHealthPlan(healthPlan): HealthPlanDTO {
    if (healthPlan) return HealthPlanDTO.of(healthPlan)
    return HealthPlanDTO.of({});
  }

  toRequest() {
    return {
      ...this,
      readjustmentPeriod: DateFormat.monthYearFromFormated(this.readjustmentPeriod),
      healthPlan: this.healthPlanEmployee && this.healthPlanEmployeePayedByDB1
        ? this.healthPlan.id
        : null,
      managerId: this.manager.id,
      godfatherId: this.godfather.id,
      address: {
        ...this.address,
        country: this.address.country.id,
        stateId: this.address.state.id,
        cityId: this.address.city.id,
      },
    };
  }

  formatYearMonth(value) {
    if (value === undefined || value === null) return '';
    return DateFormat.monthYearToFormated(value)
  }

  agreementListWithoutTotal(): Agreement[] {
    return this.agreementList.filter(agreement => agreement.description !== 'Total')
  }

  agreementTotal(): Agreement[] {
    return this.agreementList.filter(agreement => agreement.description === 'Total')
  }

  get previousTotal(): number {
    return this.agreementListWithoutTotal()
      .filter(agreement => agreement.numericField)
      .reduce((sum, agreement) => sum + agreement.previousAgreementValue, 0)
  }

  get newAgreementTotal(): number {
    return this.agreementListWithoutTotal()
      .filter(agreement => agreement.numericField)
      .reduce((sum, agreement) => sum + agreement.newAgreementValue, 0)
  }

  total(): number {
    const totalAgreement = this.agreementTotal()
    totalAgreement[0].newAgreement = this.newAgreementTotal.toFixed(2).toString();
    totalAgreement[0].previousAgreement = this.previousTotal.toFixed(2).toString();
    const persentTotal = totalAgreement[0].persentReadjustment
    return parseFloat(persentTotal);
  }

  get formatCountDate() {
    return moment(this.countDate).format('DD/MM/YYYY');
  }

  get containsDuczSyndicate() {
    const isSyndicate = agreement => agreement.description === FixedAgreement.DUCZ_SYNDICATE_FREE
    return this.agreementList.filter(isSyndicate).length !== 0;
  }

  addDuczSyndicate() {
    this.agreementList.push(new Agreement({
      description: FixedAgreement.DUCZ_SYNDICATE_FREE,
      numericField: true,
      validUntil: null,
    }))
  }

  removeDuczSyndicate() {
    const isDuczSyndicate = (item: Agreement) => item.description
      === FixedAgreement.DUCZ_SYNDICATE_FREE;
    this.agreementList.splice(this.agreementList.findIndex(isDuczSyndicate), 1);
  }
}
