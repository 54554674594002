


























































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import {
  hasEmployeeAdministratorAuthority,
  hasManagerAuthority,
  hasFacilitiesOrEmployeeAdministratorAuthority,
} from '@/shared/directive/has-authority.directive';
import TabButton from '@/shared/tab-button.component.vue';
import CardTitle from '@/shared/card/card-title.component.vue';
import AuthenticationService from '@/domain/security/authentication.service';
import Authority from '@/domain/security/authority.entity';
import AuthorityEnum from '@/domain/security/authority.enum';
import AuthorityStore from '@/domain/security/authority.store';
import Assignment from '@/domain/drd/entity/assignment.entity';
import DrdService from '@/domain/drd/service/drd.service';
import DrdFilter from '@/domain/drd/entity/filter-drd.entity';
import EmployeeTalentLevel from '@/domain/employees/entity/employee-talent-level.entity';
import EmployeeService from '../../../domain/employees/service/employee.service';
import Profile from './employee-profile.component.vue';
import Employee from '../../../domain/employees/entity/employee.entity';
import CareerUpdate from '../../../domain/employees/entity/career-update.entity';
import ProfileMenu from './profile-menu.component.vue';
import HistoryModal from './modal/history-modal.component.vue';
import TabComponent from './tab-components/profile-tabs.component.vue';
import PersonalDataComponent from './tab-components/personal-data/personal-data.component.vue';
import CarrerDataComponent from './tab-components/career-data/carrer-data.component.vue';
import EmployeeActivitiesTableComponent from './tab-components/activities/employee-activities-table.component.vue';
import ProfessionalDataComponent from './tab-components/professional-data/professional-data.component.vue';
import VacationComponent from './tab-components/vacation-payslip/vacation.component.vue';
import ScoreComponent from './tab-components/score/score.component.vue';
import { scoreSource } from './tab-components/score/score-utils';
import SkillMapDataComponent from './tab-components/skill-map-data/skill-map-data.component.vue';
import AllPlansView from './tab-components/all-plans/all-plans.view.vue';
import EmployeeWageComponent from './tab-components/employee-wages/employee-wage.component.vue';

@Component({
  components: {
    TabButton,
    Profile,
    ProfileMenu,
    HistoryModal,
    CardTitle,
    TabComponent,
    PersonalDataComponent,
    CarrerDataComponent,
    EmployeeActivitiesTableComponent,
    ProfessionalDataComponent,
    VacationComponent,
    ScoreComponent,
    SkillMapDataComponent,
    AllPlansView,
    EmployeeWageComponent,
  },
  directives: {
    hasEmployeeAdministratorAuthority,
    hasManagerAuthority,
    hasFacilitiesOrEmployeeAdministratorAuthority,
  },
})
export default class Employees extends Vue {
  employee: Employee = new Employee({});

  updates: CareerUpdate[] = [];

  aux: CareerUpdate = new CareerUpdate({});

  showCreate: boolean = false;

  showAlert: boolean = false;

  alertMessage: string = 'Deseja realmente prosseguir? Ao clicar no botão Sim, o evento será imediatamente descartado.';

  deletableUpdate: CareerUpdate = new CareerUpdate({});

  showHistory: boolean = false;

  reset: boolean = false;

  edit: boolean = false;

  key: string = '';

  source = scoreSource.employee

  authority!: Authority;

  isAdministrator: boolean = false;

  isFacilitiesAdministrator: boolean = false;

  accessProfessionalData: boolean = false;

  updateProfessionalData: boolean = false;

  createOrUpdateScore: boolean = false;

  readScore: boolean = false;

  createResignation: boolean = false;

  updateGodfather: boolean = false;

  updateAcompanied: boolean = false;

  updateNeedTraining: boolean = false;

  updateCarrerUpdate: boolean = false;

  createCarrerUpdate: boolean = false;

  deleteCarrerUpdate: boolean = false;

  deletePastCarrerUpdate: boolean = false;

  readCarrerUpdateHistory: boolean = false;

  readPersonalData: boolean = false;

  isManager: boolean = false;

  readEmployeeRemunaration: boolean = false;

  readEmployeeActivity: boolean = false;

  subordinateList!: string[];

  isSubordinate: boolean = false;

  isEmployeeAdministrator: boolean = false;

  isPersonalDepartament: boolean = false;

  isBusinessPartiner: boolean = false;

  updateNeedTrainning: boolean = false;

  hasUpdatePastCareerUpdateEvent: boolean = false;

  hasAccessSkillMapTab: boolean = false;

  hasCreatePdiPlan: boolean = false;

  hasUpdatePdiPlan: boolean = false;

  hasDeletePdiPlan: boolean = false;

  hasAccessPdiTab: boolean = false;

  hasUpdateReceiveCommission: boolean = false;

  loggedUserEmail: string = '';

  assignment: Assignment = new Assignment();

  sortBy: Array<string> = ['assignment.role.name'];

  sortDesc: boolean[] = [false];

  performanceDescriptionId: string = '';

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  talentLevel: EmployeeTalentLevel = new EmployeeTalentLevel({});

  showTalentLevel: boolean = false;

  back() {
    window.history.go(-1);
  }

  go(value: string) {
    return this.$router.push(value);
  }

  get current() {
    return CareerUpdate.of(this.updates[0]);
  }

  get hasContent() {
    return this.updates.length === 0;
  }

  get isLoggedUserProfile(): boolean {
    return this.employee.availableEmails.some(
      (email: string) => email.toLowerCase() === this.loggedUserEmail,
    );
  }

  async created() {
    this.$destroy();
    this.getProfile();
    await this.validationAuthorities();
    this.checkIsSubordinate();
  }

  @Watch('$route.params')
  getProfile() {
    if (this.$route.params.username) {
      EmployeeService.getEmployeeByUsername(this.$route.params.username)
        .then((response: Employee) => {
          this.employee = response;
          sessionStorage.setItem('employeId', this.employee.id);
        })
        .catch(error => VbSnackbarService.handleHttpError(error));
    }
  }

  async validationAuthorities() {
    this.loggedUserEmail = AuthenticationService.userLoggedIn().userName.toLowerCase();
    await AuthorityStore.fetchedAuthority().then((authority) => {
      this.authority = authority;
    });
    this.isAdministrator = this.authority.isAdministrator();
    this.isFacilitiesAdministrator = this.authority.isFacilitiesAdministrator();
    this.isEmployeeAdministrator = this.authority.isEmployeeAdministrator();
    this.isPersonalDepartament = this.authority.isPersonalDepartment();
    this.isBusinessPartiner = this.authority.isBusinnesPartner();
    this.accessProfessionalData = this.authority.hasAccessProfessionalData();
    this.updateProfessionalData = this.authority.hasUpdateProfessionalData();
    this.createOrUpdateScore = this.authority.hasCreateOrUpdateEmployeeScore();
    this.readScore = this.authority.hasReadScore();
    this.createResignation = this.authority.hasCreateResignation();
    this.updateGodfather = this.authority.hasUpdateGodfather();
    this.updateAcompanied = this.authority.hasUpdateAcompanied();
    this.updateNeedTraining = this.authority.hasUpdateNeedTraining();
    this.createCarrerUpdate = this.authority.hasCreateCarrerUpdate();
    this.updateCarrerUpdate = this.authority.hasUpdateCarrerUpdate();
    this.deleteCarrerUpdate = this.authority.hasDeleteCarrerUpdate();
    this.deletePastCarrerUpdate = this.authority.hasDeletePastCarrerUpdate();
    this.readCarrerUpdateHistory = this.authority.hasReadCarrerUpdateHistory();
    this.readPersonalData = this.authority.hasReadPersonalData();
    this.isManager = this.authority.isManager();
    this.readEmployeeRemunaration = this.authority.hasReadEmployeeRemunaration();
    this.readEmployeeActivity = this.authority.hasReadEmployeeActivity();
    this.updateNeedTrainning = this.authority.hasUpdateNeedTraining();
    this.hasUpdatePastCareerUpdateEvent = this.authority.hasUpdatePastCareerUpdateEvent();
    this.hasAccessSkillMapTab = this.authority.isSkillMapTab();
    this.hasCreatePdiPlan = this.authority.hasCreatePdiPlan();
    this.hasUpdatePdiPlan = this.authority.hasUpdatePdiPlan();
    this.hasDeletePdiPlan = this.authority.hasDeletePdiPlan();
    this.hasAccessPdiTab = this.authority.hasAccessPdiTab();
    this.hasUpdateReceiveCommission = this.authority.hasUpdateReceiveCommission();
  }

  checkIsSubordinate() {
    EmployeeService.getAllSubordinates().then((response) => {
      this.subordinateList = response;
      this.isSubordinate = this.subordinateList.includes(this.employee.id);
    });
  }

  get isTechSubordinate(): boolean {
    return this.employee.techLeadAvailableEmails.includes(this.loggedUserEmail);
  }

  get checkAccessScore(): boolean {
    return (this.readScore && this.isSubordinate)
    || (this.readScore && this.verifyAccessTabIfNotManager())
  }

  get checkAccessPersonalData(): boolean {
    return this.isLoggedUserProfile
    || (this.readPersonalData && this.isFacilitiesAdministrator)
    || (this.readPersonalData && this.isSubordinate)
    || (this.readPersonalData && this.verifyAccessTabIfNotManager())
  }

  get checkAccessProfessionalData(): boolean {
    return (this.accessProfessionalData && this.isSubordinate)
    || (this.accessProfessionalData && this.isTechSubordinate)
    || (this.accessProfessionalData && this.verifyAccessTabIfNotManager())
  }

  get checkAccessEmployeeRemunaration(): boolean {
    return this.isLoggedUserProfile
    || (this.readEmployeeRemunaration && this.isSubordinate)
    || (this.readEmployeeRemunaration && this.verifyAccessTabIfNotManager())
  }

  get checkAccessCommission(): boolean {
    return !this.isLoggedUserProfile
    && ((this.hasUpdateReceiveCommission && this.isSubordinate)
    || (this.hasUpdateReceiveCommission && this.verifyAccessTabIfNotManager()))
  }

  get checkAccessEmployeeActivity(): boolean {
    return this.isLoggedUserProfile
    || (this.readEmployeeActivity && this.isSubordinate)
    || (this.readEmployeeActivity && this.isTechSubordinate)
    || (this.readEmployeeActivity && this.verifyAccessTabIfNotManager())
  }

  hasReadScore(): boolean {
    return this.authority.hasAuthorities([AuthorityEnum.READ_SCORE]);
  }

  get checkAccessUpdateNeedTraining(): boolean {
    return (this.updateNeedTrainning && this.isSubordinate)
    || (this.updateNeedTrainning && this.verifyAccessTabIfNotManager());
  }

  get checkAccessUpdateAcompanied(): boolean {
    return (this.isSubordinate && this.updateAcompanied)
    || (this.updateAcompanied && this.verifyAccessTabIfNotManager());
  }

  get checkAccessUpdateGodfather(): boolean {
    return (this.isSubordinate && this.updateGodfather)
    || (this.updateGodfather && this.verifyAccessTabIfNotManager());
  }

  get checkAccessCreateResignation(): boolean {
    return (this.createResignation && this.isSubordinate)
    || (this.createResignation && this.verifyAccessTabIfNotManager());
  }

  get checkAccessCreateCareerUpdate(): boolean {
    return ((this.createCarrerUpdate && this.isSubordinate)
    || (this.createCarrerUpdate && this.verifyAccessTabIfNotManager()))
    && !this.isLoggedUserProfile;
  }

  get checkAccessReadUpdateHistory(): boolean {
    return (this.readCarrerUpdateHistory && this.isSubordinate)
    || (this.readCarrerUpdateHistory && this.verifyAccessTabIfNotManager())
  }

  get unlockDeleteCareerUpdate(): boolean {
    return ((this.deleteCarrerUpdate && this.isSubordinate)
    || (this.deleteCarrerUpdate && this.verifyAccessTabIfNotManager()))
    && !this.isLoggedUserProfile;
  }

  get unlockDeletePastCareerUpdate(): boolean {
    return ((this.deletePastCarrerUpdate && this.isSubordinate)
    || (this.deletePastCarrerUpdate && this.verifyAccessTabIfNotManager()))
    && !this.isLoggedUserProfile;
  }

  get unlockUpdateCareerUpdate(): boolean {
    return ((this.updateCarrerUpdate && this.isSubordinate)
    || (this.updateCarrerUpdate && this.verifyAccessTabIfNotManager()))
    && !this.isLoggedUserProfile;
  }

  get updatePastCareerUpdateEvent(): boolean {
    return ((this.hasUpdatePastCareerUpdateEvent && this.isSubordinate)
    || (this.hasUpdatePastCareerUpdateEvent && this.verifyAccessTabIfNotManager()))
    && !this.isLoggedUserProfile;
  }

  get checkAccessSkillTab(): boolean {
    return this.isLoggedUserProfile
    || (this.hasAccessSkillMapTab && this.isSubordinate)
    || (this.hasAccessSkillMapTab && this.isTechSubordinate)
    || (this.hasAccessSkillMapTab && this.verifyAccessTabIfNotManager())
  }

  get checkAccessPdiTab(): boolean {
    return this.isLoggedUserProfile
    || (this.hasAccessPdiTab && this.isSubordinate)
    || (this.hasAccessPdiTab && this.isTechSubordinate)
    || (this.hasAccessPdiTab && this.verifyAccessTabIfNotManager());
  }

  get checkUpdatePdiPlan(): boolean {
    return this.isLoggedUserProfile
    || (this.hasUpdatePdiPlan && this.isSubordinate)
    || (this.hasUpdatePdiPlan && this.verifyAccessTabIfNotManager())
  }

  get checkDeletePdiPlan(): boolean {
    return (this.hasDeletePdiPlan && this.isSubordinate)
    || (this.hasDeletePdiPlan && this.verifyAccessTabIfNotManager())
  }

  verifyAccessTabIfNotManager(): boolean {
    return this.isEmployeeAdministrator
        || this.isBusinessPartiner
        || this.isPersonalDepartament
        || this.isAdministrator
  }

  setAssignment(assignment: Assignment) {
    this.assignment = assignment;
    this.getDrdId();
  }

  getDrdId() {
    const filter = new DrdFilter({ assignment: this.assignment.id })
    DrdService.findDrd(this.pagination, this.sortBy.toString(), filter)
      .then(response => this.performanceDescriptionId = response.content[0].id);
  }
}
