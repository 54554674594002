





























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import CommissionList from '@/domain/commission/entity/commission-list.entity';
import MobileHandler from '@/shared/utils/mobile-handler';
import PaginationComponent from '@/shared/pagination.component.vue';
import DateFormat from '@/shared/utils/date-format';
import Rules from '@/shared/rules/rules';
import CurrencyField from '@/shared/currency-field.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import CommissionService from '@/domain/commission/service/commission.service';
import { VbSnackbarService } from 'bag-of-holding-library';
import LoadingComponent from '@/shared/loading/loading.component.vue';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';

@Component({
  components: {
    PaginationComponent,
    CurrencyField,
    LabelSlot,
    LoadingComponent,
    ModalWarning,
  },
})
export default class CommissionTable extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  }) items!: CommissionList[];

  @Prop() page!: any;

  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  @Prop({
    type: Array,
    default: () => [],
  }) selectedItems!: CommissionList[];

  @Prop({
    type: Boolean,
    default: false,
  }) hasUpdateCommission!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) hasAccessConcludeCommission!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) hasDeleteCommission!: boolean;

  isMobile = false;

  rules: Rules = new Rules();

  editedValue: number = 0;

  headers = [
    {
      text: 'Colaborador',
      value: 'employeeName',
    },
    {
      text: 'Criado por',
      value: 'createdBy',
    },
    {
      text: 'Competência',
      value: 'competence',
    },
    {
      text: 'Criado em',
      value: 'createdDateFormatted',
    },
    {
      text: 'Valor',
      value: 'value',
      align: 'center',
    },
    {
      text: 'Status',
      value: 'concluded',
    },
    {
      text: 'Ações',
      value: 'action',
      align: 'center',
    },
  ]

  loadingEditValue: boolean = false;

  itemDeleted!: CommissionList;

  openDeleteModalWarning: boolean = false;

  loadingDelete: boolean = false;

  deleteModalWarningOptions: ModalOptions = {
    title: 'Atenção',
    description: 'Deseja realmente prosseguir? Ao clicar no botão Sim, as alterações serão imediatamente realizadas.',
    loadingOk: this.loadingDelete,
    showCancel: !this.loadingDelete,
    showOk: true,
    labelOk: 'Sim',
    labelCancel: 'Não',
    width: 500,
  }

  updateTable(pagination: any): void {
    this.$emit('update', pagination);
  }

  onResize() {
    this.isMobile = MobileHandler.isLessThan(600);
  }

  formatCompetence(date: string) {
    return DateFormat.monthNumberYear(date)
  }

  updateSelectedItems(selectedItems: CommissionList[]) {
    this.$emit('updateSelectedItems', selectedItems);
  }

  openEditValue(itemValue: number) {
    this.editedValue = itemValue;
  }

  editValue(item: CommissionList) {
    const { form }: any = this.$refs
    const isValid = form.validate()
    if (!isValid) return;
    this.loadingEditValue = true;
    CommissionService.editValue(item.id, this.editedValue)
      .then(() => {
        item.value = this.editedValue;
        VbSnackbarService.showSuccess('Valor alterado com sucesso!');
      }).catch(VbSnackbarService.handleHttpError)
      .finally(() => this.loadingEditValue = false)
  }

  deleteItem() {
    this.itemDeleted.loadingDelete = true;
    this.closeDeleteModalWarning();
    CommissionService.delete(this.itemDeleted.id)
      .then(() => VbSnackbarService.showSuccess('Comissão deletada com sucesso!'))
      .catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.itemDeleted.loadingDelete = false;
        this.updateTable(this.page);
      })
  }

  closeDeleteModalWarning() {
    this.openDeleteModalWarning = false;
  }

  handleOpenDeleteModalWarning(item: CommissionList) {
    this.itemDeleted = item;
    this.openDeleteModalWarning = true;
  }
}
