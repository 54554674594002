import axios from 'axios';
import CommissionSimpleReport from '../entity/commission-simple-report.entity';
import Commission from '../entity/commission.entity';
import EmployeeSubordinateReceiveCommission from '../entity/commission-employee-subordinate.entity';
import CommissionFilter from '../entity/commission-filter.entity';

export default class CommissionService {
  public static async getSimpleReport(): Promise<CommissionSimpleReport[]> {
    return axios.get('/api/commissions/simple-report')
      .then(({ data }: any) => data
        .map((it: CommissionSimpleReport) => new CommissionSimpleReport(it)))
  }

  public static async createCommission(
    competence: string,
    commissions: Commission[],
  ): Promise<void> {
    return axios.post('/api/commissions', {
      competence,
      commissions,
    });
  }

  public static async getSubordinatesReceiveCommission():
  Promise<EmployeeSubordinateReceiveCommission[]> {
    return axios.get('/api/commissions/subordinates')
      .then(({ data }: any) => data
        .map((it:
          EmployeeSubordinateReceiveCommission) => new EmployeeSubordinateReceiveCommission(it)));
  }

  public static async getAll(
    filter: CommissionFilter,
    page: number,
    size: number,
    report: boolean = false,
  ): Promise<any> {
    return axios.get('/api/commissions', {
      params: {
        ...filter, page: page - 1, size, report,
      },
    })
  }

  public static async concludeCommissions(commissionIds: string[]): Promise<void> {
    return axios.put('/api/commissions/concluded', commissionIds);
  }

  public static async getByEmployeeUsername(
    username: string,
    page: number,
    size: number,
  ): Promise<any> {
    return axios.get(`/api/commissions/employee/${username}`, {
      params: { page, size },
    })
  }

  public static async editValue(commissionId: string, value: number) {
    return axios.put(`/api/commissions/${commissionId}/update-value`, { value })
  }

  public static async delete(commissionId: string) {
    return axios.delete(`/api/commissions/${commissionId}`);
  }
}
