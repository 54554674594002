import {
  Vue, Prop, Component, Emit,
} from 'vue-property-decorator';
import ModalOptions from './modal-options.entity';

@Component
export default class ModalConfirmMixin extends Vue {
  @Prop({
    required: true,
  })
  value!: boolean;

  @Prop({
    default: () => ({
      title: 'Importante',
      description: 'Deseja realmente prosseguir? Ao clicar no botão Sim, as alterações serão imediatamente realizadas.',
      showOk: true,
      labelOk: 'Sim',
      showCancel: true,
      labelCancel: 'Não',
      width: 500,
    }),
  }) options!: ModalOptions

  @Emit('ok')
  ok(): void {}

  @Emit('cancel')
  cancel(): void {}
}
