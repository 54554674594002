





























import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import PanelScore from '@/views/drd/read/common-components/drd-panel.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import ScoreService from '@/domain/employees/service/score.service';
import Score from '@/domain/employees/entity/score.entity';
import ScoreDescriptionRead from '@/views/score/read/score-description-read.component.vue';
import ProfessionalDataService from '@/domain/employees/service/professional-data.service';
import ProfessionalData from '@/domain/employees/entity/professional-data.entity';
import EmployeeRevenueComponent from './employee-revenue.component.vue';
import booleanValuesOption from '../score/boolean-values-option';

@Component({
  components: {
    LabelSlot,
    PanelScore,
    ScoreDescriptionRead,
    EmployeeRevenueComponent,
  },
})
export default class EmployeeScoreComponent extends Vue {
  @Prop({
    type: String,
  }) employeeId!: string;

  @Prop({
    type: String,
    default: '',
  }) badgeMessage!: string;

  @Prop() checkAccessCommission!: boolean;

  loading: boolean = true;

  scores: Score[] = [];

  booleanValuesOption = booleanValuesOption;

  saveReceiveCommissionLoading: boolean = false;

  professionalData: ProfessionalData = new ProfessionalData();

  receiveCommission: boolean = false;

  created(): void {
    this.loadLoggedUserScores();
  }

  loadLoggedUserScores(): void {
    ScoreService.getLoggedUserScores(this.employeeId).then((data: Score[]) => {
      this.scores = data;
    }).catch((err) => {
      VbSnackbarService.handleHttpError(err);
    }).finally(() => this.loading = false);
  }

  handleReceiveCommission() {
    this.saveReceiveCommissionLoading = true;
    ProfessionalDataService
      .updateReceiveCommission(this.employeeId,
        this.receiveCommission)
      .then(() => VbSnackbarService.showSuccess('Informação salva com sucesso!'))
      .catch((error) => {
        this.receiveCommission = !this.receiveCommission
        VbSnackbarService.showError(error.response.data.messages.toString());
      }).finally(() => {
        this.saveReceiveCommissionLoading = false;
      })
  }
}
