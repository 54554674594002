export default class EmployeeSubordinateReceiveCommission {
  id: string

  employeeName: string

  currencySymbol: string


  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.employeeName = obj.employeeName || '';
    this.currencySymbol = obj.currencySymbol || '';
  }
}
