var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],class:("data-table " + (_vm.loading ? 'data-table-loading' : '')),attrs:{"headers":_vm.headers,"items":_vm.items,"show-select":_vm.hasAccessConcludeCommission,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Carregando...","no-data-text":"Nenhum dado encontrado...","page":_vm.page.page,"sort-by":_vm.page.sortBy,"sort-desc":_vm.page.sortDesc,"items-per-page":_vm.page.itemsPerPage,"page-count":_vm.page.pageCount},on:{"input":_vm.updateSelectedItems,"update:sortBy":function($event){return _vm.$set(_vm.page, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.page, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.page, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.page, "sortDesc", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.page, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.page, "itemsPerPage", $event)},"update:pageCount":function($event){return _vm.$set(_vm.page, "pageCount", $event)},"update:page-count":function($event){return _vm.$set(_vm.page, "pageCount", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',[_c('modal-warning',{attrs:{"options":_vm.deleteModalWarningOptions,"value":_vm.openDeleteModalWarning},on:{"ok":_vm.deleteItem,"cancel":_vm.closeDeleteModalWarning}})],1)]},proxy:true},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('div',[(_vm.hasUpdateCommission)?_c('v-edit-dialog',{on:{"open":function($event){return _vm.openEditValue(item.value)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c('v-form',{ref:"form"},[_c('label-slot',{attrs:{"label":"Valor"}},[_c('currency-field',{attrs:{"placeholder":"Informe o valor","max-length":100,"currencySymbol":item.currencySymbol},model:{value:(_vm.editedValue),callback:function ($$v) {_vm.editedValue=$$v},expression:"editedValue"}})],1)],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"depressed":"","text":"","color":"primary","loading":_vm.loadingEditValue},on:{"click":function($event){return _vm.editValue(item)}}},[_vm._v(" Salvar ")])],1)],1)]},proxy:true}],null,true)},[_c('div',[(!_vm.isMobile)?_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center"},'div',attrs,false),on),[_c('span',[_vm._v(" "+_vm._s(((item.currencySymbol) + " " + (item.valueFormatted)))+" ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Clique para editar")])]):_c('div',[_c('span',[_vm._v(" "+_vm._s(((item.currencySymbol) + " " + (item.valueFormatted)))+" ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1)]):_c('span',[_vm._v(" "+_vm._s(((item.currencySymbol) + " " + (item.valueFormatted)))+" ")])],1)]}},{key:"item.competence",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatCompetence(item.competence)))])])]}},{key:"item.concluded",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{class:item.concluded ? 'success--text' : 'warning--text'},[_vm._v(" "+_vm._s(item.concluded ? 'Concluído' : 'Não Concluído')+" ")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"icon":"","color":"error","loading":item.loadingDelete,"disabled":!_vm.hasDeleteCommission},on:{"click":function($event){return _vm.handleOpenDeleteModalWarning(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1)]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}),_c('pagination-component',{staticClass:"mt-8 ml-md-2",attrs:{"pagination":_vm.page,"totalVisible":_vm.isMobile ? 5 : 8},on:{"input":_vm.updateTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }