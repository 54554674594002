export default class CommissionFilter {
  employeeName: string;

  createdByName: string;

  competence: string;

  isConcluded: boolean | null = null;

  constructor(obj: any = {}) {
    this.employeeName = obj.employeeName || '';
    this.createdByName = obj.createdByName || '';
    this.competence = obj.competence || '';
    this.isConcluded = obj.isConcluded || null;
  }
}
