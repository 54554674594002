import RouteCheckerTo from '@/views/route-checker';
import CommissionView from './commision.view.vue';
import CommissionListComponent from './list/commission-list.component.vue';
import CommissionCreateComponent from './create/commission-create.component.vue';

export default [
  {
    path: '/commissions',
    name: 'Commissões',
    component: CommissionView,
    children: [
      {
        path: '/',
        name: 'Listagem Comissões',
        component: CommissionListComponent,
        beforeEnter: RouteCheckerTo.hasReadCommission,
      },
      {
        path: '/commissions/create',
        name: 'Criar Comissões',
        component: CommissionCreateComponent,
        beforeEnter: RouteCheckerTo.hasAccessCreateCommission,
      },
    ],
  },
];
